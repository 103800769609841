import React, { useState } from "react";
import Table from "react-bootstrap/Table";
//Components
import Pagination from "./Pagination";

const TableContainerReports = ({ data, loading }) => {
  const [currentPage, setCurrentPage] = useState(1);

  const data_per_page = 10;
  const indexOfLastData = currentPage * data_per_page;
  const indexOfFirstData = indexOfLastData - data_per_page;
  const currentDatas = data.slice(indexOfFirstData, indexOfLastData);

  const getReportReason = (id) => {
    if (id === "1") {
      return "Spam";
    }
    if (id === "2") {
      return "Sahtekarlık veya dolandırıcılık";
    }
    if (id === "3") {
      return "Bundan hoşlanmadım";
    }
    if (id === "4") {
      return "Yanlış Bilgiler";
    }
    if (id === "5") {
      return "Yasadışı ürünlerin satışı";
    }
    if (id === "6") {
      return "Başka bir şey";
    } else {
      return "Spam";
	}
  };

  return (
    <>
      <div className="table_container">
        {loading === true ? (
          <>Yükleniyor</>
        ) : (
          <>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Şikayet Alan ID</th>
                  <th>Tür</th>
                  <th>Rapor</th>
                  <th>Tarihi</th>
                </tr>
              </thead>
              <tbody>
                {currentDatas.map((data) => (
                  <tr>
                    <td>{data.reported_id}</td>
                    <td>{data.type === "advert" ? "İlan" : "Kişi"}</td>
                    <td>{getReportReason(data.reason)}</td>
                    <td>{data?.created_at?.split("T")[0]}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <Pagination
              dataPerPage={data_per_page}
              totalData={data.length}
              paginate={(index) => setCurrentPage(index)}
            />
          </>
        )}
      </div>
    </>
  );
};

export default TableContainerReports;
