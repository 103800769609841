import { Routes, Route } from "react-router-dom";
//Components
import Login from "./components/Olds/Login";
import Layout from "./components/Olds/Layout";
import Missing from "./components/Olds/Missing";
import Unauthorized from "./components/Olds/Unauthorized";
import RequireAuth from "./components/Olds/RequireAuth";
import PersistLogin from "./components/Olds/PersistLogin";
//Pages
import Landing from "./pages/Landing";
import Panel from "./pages/Panel";
import Share from "./pages/Share";
import Privacy from "./pages/Privacy";
import Admin from './components/Panel/Admin';
import Feedbacks from "./pages/Feedbacks";
import Reports from "./pages/Reports";
import Notifications from "./pages/Notifications";
import Banners from './pages/Banners';
//Constants
import Roles from './constants/Roles';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        {/* public routes */}
        <Route path="/" element={<Landing />} />
        <Route path="login" element={<Login />} />
        <Route path="share" element={<Share />} />
        <Route path="gizlilik-politikasi" element={<Privacy />} />
        <Route path="unauthorized" element={<Unauthorized />} />
        <Route path="admin" element={<Admin />} />
        {/* we want to protect these routes */}
        <Route element={<PersistLogin />}>
          <Route
            element={<RequireAuth allowedRoles={[Roles.Admin, Roles.Cafe]} />}
          >
            <Route path="panel" element={<Panel />} />
            <Route path="feedbacks" element={<Feedbacks />} />
            <Route path="reports" element={<Reports />} />
            <Route path="notifications" element={<Notifications />} />
            <Route path="banners" element={<Banners />} />
          </Route>
        </Route>
        {/* catch all */}
        <Route path="*" element={<Missing />} />
      </Route>
    </Routes>
  );
}

export default App;
