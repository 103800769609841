import Button from "@mui/material/Button";
//Assest
import companyLogo from "../images/logo.png";
import bg from "../images/bg.png";
import { Link } from "react-router-dom";

const Landing = () => {
  const changePage = () => {
	  console.log('asdasdfhgasjfw e')
  };

  return (
    <div>
      <div className="landing_container">
        <img src={companyLogo} alt="KafeGram" className="logo_icon" />
        <img src={bg} alt="KafeGram" className="bg_icon" />
        <h1 className="welcome_text">Çekmece App</h1>
        <div className="main_button">App Store</div>
        <div className="main_button">Google Play</div>
        <div className="textRow">
          <div className="term">Hizmet Koşulları</div>
          <Link to="/gizlilik-politikasi">
            <div className="term" onClick={changePage}>
              Gizlilik Politikası
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Landing;
