//Components
import PanelContainer from "../components/Layouts/PanelContainer";
import BannersInside from "./BannersInside";
import Unauthorized from "../components/Olds/Unauthorized";
//Hooks
import useAuth from "../hooks/useAuth";
//Constants
import Roles from "../constants/Roles";

const Banners = () => {
  const { auth } = useAuth();

  return auth.roles.includes(Roles.Admin) ? (
    <PanelContainer>
      <BannersInside />
    </PanelContainer>
  ) : (
    <Unauthorized />
  );
};

export default Banners;
