import React, { useState } from "react";
import Button from "@mui/material/Button";
//Components
import PageTitle from "../components/Panel/PageTitle";
//API
import useAxiosPrivate from "../hooks/useAxiosPrivate";

const title = "Bildirim Gönderme";

const API_URL = "/send_notification_to_all";

const NotificationsInside = () => {
  const axiosPrivate = useAxiosPrivate();

  const [ntitle, setNTitle] = useState("");
  const [body, setBody] = useState("");

  const sendNotifications = async () => {
    try {
      let parameters = { title: ntitle, body: body };
      await axiosPrivate.post(API_URL, JSON.stringify(parameters), {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        withCredentials: true,
      });
      alert("Bildirimler Gönderildi!");
    } catch (err) {
      alert(err);
      // TODO: Errorhandling..
    }
  };

  return (
    <>
      <PageTitle title={title} />
      <div className="notifications_container">
        <form className="notifications_form">
          <input
            className="notif_title_input"
            placeholder="Başlık giriniz"
            type="text"
            name="title"
            value={ntitle}
            onChange={(e) => {
              setNTitle(e.target.value);
            }}
          />
          <textarea
            className="notif_body_input"
            placeholder="İçerik giriniz"
            type="text"
            name="body"
            multiple={true}
            value={body}
            onChange={(e) => {
              setBody(e.target.value);
            }}
          />
        </form>
        <Button onClick={sendNotifications} color="primary" variant="text">
          Gönder
        </Button>
      </div>
    </>
  );
};

export default NotificationsInside;
