import React, { useState, useEffect } from "react";
//Components
import PageTitle from "../components/Panel/PageTitle";
import TableContainerReports from "../components/TableContainerReports";
//API
import useAxiosPrivate from "../hooks/useAxiosPrivate";

const title = "Şikayetler";

const API_URL = "/get_all_reports";

const FeedbacksInside = () => {
  const axiosPrivate = useAxiosPrivate();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchDatas = async () => {
      setLoading(true);
      try {
        let parameters = {};
        const response = await axiosPrivate.post(
          API_URL,
          JSON.stringify(parameters),
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            withCredentials: true,
          }
        );
        setLoading(false);
        if (response.status === 200) {
          setData(response.data.data);
        }
      } catch (err) {
        alert(err);
        setLoading(false);
        // TODO: Errorhandling..
      }
    };

    fetchDatas();
  }, []);

  return (
    <>
      <PageTitle title={title} total={data.length} />
      <TableContainerReports data={data} loading={loading} />
    </>
  );
};

export default FeedbacksInside;
