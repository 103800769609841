/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from "react";
import Table from "react-bootstrap/Table";
//Components
import Pagination from "./Pagination";
//API
import useAxiosPrivate from "../hooks/useAxiosPrivate";

const API_URL = "/delete_banner";

const TableContainerUsers = ({ data, loading, onDone }) => {
  const axiosPrivate = useAxiosPrivate();

  const [currentPage, setCurrentPage] = useState(1);

  const data_per_page = 10;
  const indexOfLastData = currentPage * data_per_page;
  const indexOfFirstData = indexOfLastData - data_per_page;
  const currentDatas = data.slice(indexOfFirstData, indexOfLastData);

  const callApi = async (id) => {
    try {
      let parameters = { banner_id: id };
      const response = await axiosPrivate.post(
        API_URL,
        JSON.stringify(parameters),
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );
      onDone();
    } catch (err) {
      alert(err);
    }
  };

  return (
    <>
      <div className="table_container">
        {loading === true ? (
          <>Yükleniyor</>
        ) : (
          <>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Resim</th>
                  <th>Sil</th>
                </tr>
              </thead>
              <tbody>
                {currentDatas.map((user) => (
                  <tr>
                    <td>
                      <img src={user?.image_url} height="100" />
                    </td>
                    <td>
                      <img
                        className="delete_image"
                        src={require("../images/red_trash.png")}
                        height="50"
                        width="50"
                        onClick={() => {
                          callApi(user._id);
                        }}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <Pagination
              dataPerPage={data_per_page}
              totalData={data.length}
              paginate={(index) => setCurrentPage(index)}
            />
          </>
        )}
      </div>
    </>
  );
};

export default TableContainerUsers;
