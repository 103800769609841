//Components
import PanelContainer from "../components/Layouts/PanelContainer";
import NotificationsInside from "./NotificationsInside";
import Unauthorized from "../components/Olds/Unauthorized";
//Hooks
import useAuth from "../hooks/useAuth";
//Constants
import Roles from "../constants/Roles";

const Notifications = () => {
  const { auth } = useAuth();

  return auth.roles.includes(Roles.Admin) ? (
    <PanelContainer>
      <NotificationsInside />
    </PanelContainer>
  ) : (
    <Unauthorized />
  );
};

export default Notifications;
