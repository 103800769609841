import { useEffect, useState } from "react";

const Privacy = () => {
  return (
    <div className="privacy_page">
      <div className="privacy_header">gizlilik politikası</div>
      <div className="privacy_container">
        <p>
          ÇEKMECE GRUP SERVICES B.V. ve ÇEKMECE GRUP MOBİL İNTERNET SERVİSLERİ
          VE TİCARET A.Ş. KİŞİSEL VERİLERİN KORUNMASI VE İŞLENMESİNE İLİŞKİN
          AYDINLATMA METNİ 1. Veri Sorumlusu İşbu Kişisel Verilerin Korunması ve
          İşlenmesine İlişkin Aydınlatma Metni (“Aydınlatma Metni”) ile Çekmece
          grup Services B.V. ve Çekmece grup Mobil İnternet Servisleri ve
          Ticaret A.Ş. (birlikte ‘’Çekmece grup Şirketleri’’ olarak anılacaktır)
          olarak, 6698 sayılı Kişisel Verilerin Korunması Kanunu (“KVKK”)
          uyarınca, Veri Sorumlusu sıfatıyla, siz değerli müşterilerimizi KVKK
          kapsamındaki aydınlatma yükümlülüğümüz çerçevesinde bilgilendirmek
          isteriz. KVKK kapsamında kişisel veri kimliği belirli veya
          belirlenebilir gerçek kişiye ilişkin her türlü bilgiyi (“Kişisel
          Veri”) ve bunun bir özel türü olan özel nitelikli kişisel veri ise,
          ırk, etnik köken, siyasi düşünce, felsefi inanç, din, mezhep veya
          diğer inançlar, kılık ve kıyafet, dernek, vakıf ya da sendika üyeliği,
          sağlık, cinsel hayat, ceza mahkûmiyeti ve güvenlik tedbirleriyle
          ilgili verileri ile biyometrik ve genetik verileri (“Özel Nitelikli
          Kişisel Veri”) ifade eder. Bu kapsamda Kişisel Veri tanımı Özel
          Nitelikli Kişisel Verilerinizi de kapsamaktadır. 2. Kişisel Verilerin
          Toplanma Yöntemi ve İşlemenin Hukuki Sebepleri Kişisel Verileriniz,
          Çekmece grup Şirketleri ile yaptığınız işlemlerle bağlantılı olarak ve
          aşağıda Bölüm 4’te belirtilen amaç ve kapsamda, otomatik veya otomatik
          olmayan yollarla, sözlü, yazılı ve/veya elektronik şekilde ve
          aşağıdaki yöntemler ve Çekmece grup Şirketleri’nin anlaşmalı olduğu
          üçüncü kişiler vasıtasıyla toplanmaktadır: 3. Çekmece grup
          Şirketleri’ne ait internet sitesi veya uygulama (birlikte ‘’Çekmece
          grup Platformu’’) üzerinden gerçekleştirmiş olduğunuz üyelikler ve
          sohbet ve/veya mesajlaşma içerikleri dahil tüm ziyaretleriniz
          vasıtasıyla, 4. Çekmece grup Platformu üzerinden gerçekleştirmiş
          olduğunuz ön fiyatlandırma ve ekspertiz randevu süreçlerinde yazılı
          olarak verdiğiniz bilgiler vasıtasıyla, 5. Kredi başvuru süreçlerine
          ilişkin olarak Çekmece grup Platformu üzerinden doldurmuş olduğunuz
          iletişim formu vasıtasıyla, 6. Çekmece grup Şirketleri’ne ait Çekmece
          merkezlerini ziyaretleriniz esnasında sözlü ve/veya yazılı olarak
          verdiğiniz bilgiler vasıtasıyla, 7. Araç alım ve satım işlemleri
          esnasında sözlü ya da yazılı olarak veyahut çeşitli belgeler
          vasıtasıyla noter huzurunda verdiğiniz bilgiler vasıtasıyla, 8. Çağrı
          Merkezi’ne sözlü olarak ya da Müşteri Hizmetleri e-posta adresine
          yazılı olarak iletilen talep ve şikâyetler vasıtasıyla, 9. Çekmece
          grup Şirketleri’ne ait Çekmece merkezleri içerisinde yer alan güvenlik
          kamera sistemi vasıtasıyla, 10. Çekmece grup Şirketleri’nin
          müşterilerine ilişkin olarak hizmet aldığı ve iş ilişkisi içerisinde
          ve anlaşmalı olduğu üçüncü kişiler vasıtasıyla. Kişisel Verilerin
          işlenmesine ilişkin KVKK’nın 5. ve 6. maddesinde belirtilen hukuki
          sebepler aşağıdaki gibidir: 11. Açık rızanızın bulunması, 12.
          Kanunlarda açıkça öngörülmesi, 13. Fiili imkânsızlık nedeniyle
          rızasını açıklayamayacak durumda bulunan veya rızasına hukuki
          geçerlilik tanınmayan kişinin kendisinin ya da bir başkasının hayatı
          veya beden bütünlüğünün korunması için zorunlu olması, 14. Bir
          sözleşmenin kurulması veya ifasıyla ilgili olarak kişisel veri
          işlenmesi, 15. Hukuki yükümlülüklerimizin yerine getirilebilmesi için
          zorunlu olması, 16. İlgili kişinin kendisi tarafından alenileştirilmiş
          olması, 17. Bir hakkın tesisi, kullanılması veya korunması için veri
          işlemenin zorunlu olması, ve 18. Sizlerin temel hak ve özgürlüklerine
          zarar vermemek kaydıyla, meşru menfaatlerimiz için zorunlu olması. 19.
          Toplanan Kişisel Verileriniz Sizlerden topladığımız Kişisel Veriler
          aşağıda Bölüm 4'te belirttiğimiz işleme amaçlarıyla orantılı olarak
          işlediğimiz verilerinizdir. 20. Kişisel Verilerin Hangi Amaçla
          İşleneceği Kişisel Verileriniz aşağıdaki amaçlar dâhilinde açık
          rızanıza binaen veya KVKK kapsamında hukuken izin verilen diğer
          hallerde Çekmece grup Şirketleri tarafından işlenmektedir: 21.
          Müşterilerin Çekmece grup Platformu üzerinden üyeliklerinin
          sağlanması,yürütülmesi (giriş yapılması faaliyeti de dahil olmak
          üzere) ve temin edilen bilgilerin doğrulamasının yapılması (kimlik
          bilgisi, iletişim bilgisi (telefon numarası dahil olmak üzere), işlem
          güvenliği bilgisi, görsel ve işitsel kayıt bilgisi (müşterinin
          görüntüleri dahil olmak üzere), (Hukuki sebep: Sözleşmenin ifası, bir
          hakkın tesisi, kullanılması ve korunması için veri işlemenin zorunlu
          olması) 22. Müşterilerin Çekmece grup Platformu’nda bulunan
          ürünlerinin satılmasına/listelenmesine ilişkin faaliyetlerin
          yürütülmesi ve vitrin ilan veya öne çıkarma özelliklerinin satışının
          yapılması (kimlik bilgisi, iletişim bilgisi (telefon numarası dahil
          olmak üzere), müşteri işlem bilgisi, işlem güvenliği bilgisi), (Hukuki
          sebep: Sözleşmenin ifası, bir hakkın tesisi, kullanılması ve korunması
          için veri işlemenin zorunlu olması) 23. Faaliyetlerin mevzuata uygun
          yürütülmesi kapsamında müşterilerin gerçekleştirdiği ürün alım işlemi
          sonrası kargo faturası kesilmesi, vergisel ve diğer kanuni
          yükümlülüklerin yerine getirilmesi (kimlik bilgisi, iletişim bilgisi,
          müşteri işlem bilgisi), (Hukuki sebep: Kanunlarda açıkça öngörülmesi,
          hukuki yükümlülüklerimizin yerine getirilmesi için zorunlu olması,
          sözleşmenin ifası, bir hakkın tesisi, kullanılması ve korunması için
          veri işlemenin zorunlu olması) 24. Çekmece grup Platformu üzerinden
          araç alım satım işlemlerine ilişkin ön fiyatlandırma teklifi alma ve
          ekspertiz hizmeti randevu süreçlerinin yürütülmesi (kimlik bilgisi,
          iletişim bilgisi (telefon numarası dahil olmak üzere), müşteri işlem
          bilgileri, araç bilgileri, işlem güvenliği bilgileri), (Hukuki sebep:
          Sözleşmenin ifası, bir hakkın tesisi, kullanılması ve korunması için
          veri işlemenin zorunlu olması) 25. Müşterilerin Çekmece grup
          Şirketleri’ne satmak istediği araçların TSE ekspertiz hizmeti
          süreçlerinin yürütülmesi (kimlik bilgisi, iletişim bilgisi, müşteri
          işlem bilgileri, araç bilgileri), (Hukuki sebep: Sözleşmenin kurulması
          ve ifası, bir hakkın tesisi, kullanılması ve korunması için veri
          işlemenin zorunlu olması) 26. Müşterilerin araçlarının Çekmece grup
          Şirketleri tarafından satın alınması süreçlerinin yürütülmesi (kimlik
          bilgisi, iletişim bilgisi, finans bilgisi, müşteri işlem bilgisi,
          hukuki işlem bilgisi, araç bilgileri), (Hukuki sebep: Sözleşmenin
          kurulması ve ifası, hukuki yükümlülüklerimizin yerine getirilmesi için
          zorunlu olması, bir hakkın tesisi, kullanılması ve korunması için veri
          işlemenin zorunlu olması) 27. Müşterilerin Çekmece grup Şirketleri’ne
          ait araçları satın alması süreçlerinin yürütülmesi (kimlik bilgisi,
          iletişim bilgisi, finans bilgisi, müşteri işlem bilgisi, hukuki işlem
          bilgisi, araç bilgileri), (Hukuki sebep: Sözleşmenin kurulması ve
          ifası, bir hakkın tesisi, kullanılması ve korunması için veri
          işlemenin zorunlu olması, hukuki yükümlülüklerimizin yerine
          getirilmesi için zorunlu olması) 28. Kredi başvuru süreçlerinin
          yürütülmesi kapsamında müşterilerin Çekmece grup Şirketleri çağrı
          merkezi aracılığıyla bilgilendirilmesi, Çekmece merkezlerine davet
          edilmesi ve akabinde müşteri talepleri doğrultusunda ilgili finans
          kuruluşlarına (finansman şirketleri ve bankalar dahil) yönlendirme
          yapılması (kimlik bilgisi, iletişim bilgisi, finans bilgisi, meslek
          bilgisi, müşteri işlem bilgisi), (Hukuki sebep: Açık rıza) 29.
          Müşteriler tarafından anlaşmalı finans kuruluşlarından (finansman
          şirketleri ve bankalar dahil) kredi kullanılarak satın alınacak olan
          Çekmece grup Şirketleri’ne ait araçlara ilişkin kredi süreçlerinin
          yürütülebilmesi amacıyla finans kuruluşları ile bilgi paylaşılması
          (kimlik bilgisi, iletişim bilgisi, finans bilgisi, meslek bilgisi,
          müşteri işlem bilgisi, araç bilgileri, hukuki işlem bilgisi), (Hukuki
          sebep: Açık rıza) 30. Faaliyetlerin mevzuata uygun yürütülmesi
          kapsamında müşterilere yapılan araç satış veya alım işlemi sonrası
          e-fatura ya da e-arşiv faturası kesilmesi, vergisel ve diğer kanuni
          yükümlülüklerin yerine getirilmesi (kimlik bilgisi, iletişim bilgisi,
          müşteri işlem bilgisi, araç bilgileri), (Hukuki sebep: Kanunlarda
          açıkça öngörülmesi, hukuki yükümlülüklerimizin yerine getirilmesi için
          zorunlu olması, sözleşmenin ifası, bir hakkın tesisi, kullanılması ve
          korunması için veri işlemenin zorunlu olması) 31. Mal satış sonrası
          destek hizmetlerinin yürütülmesi kapsamında memnun kalınmayan
          araçların değişim ve iade işlemlerinin gerçekleştirilmesi (kimlik
          bilgisi, iletişim bilgisi, müşteri işlem bilgisi, araç bilgileri,
          hukuki işlem bilgisi, finans bilgisi), (Hukuki sebep: Sözleşmenin
          ifası, hukuki yükümlülüklerimizin yerine getirilmesi, bir hakkın
          tesisi, kullanılması ve korunması) 32. Satış sonrası Çekmece grup
          Platformu’nda, sosyal medya kanallarında (Instagram, Twitter, Youtube
          vb. dahil olmak üzere), Çekmece merkezlerinde bulunan çevrimdışı
          mağaza içi televizyonlarda ya da Çekmece grup Şirketleri tarafından
          gerçekleştirilecek halkla ilişkiler (PR) etkinliklerinde gösterilmek
          üzere veya Çekmece grup Şirketleri iç bilgilendirmesinde kullanılması
          amacıyla müşteriler ile yapılan fotoğraf ve video çekimleri ve
          röportaj süreçlerinin yürütülmesi (kimlik bilgisi, iletişim bilgisi,
          araç bilgileri, görsel ve işitsel kayıt bilgisi), (Hukuki sebep: Açık
          rıza) 33. Reklam/kampanya/promosyon, ürün pazarlama süreçleri ve
          iletişim faaliyetlerinin yürütülmesi kapsamında elektronik ticari
          iletilerin gönderilmesi (telefon, SMS, MMS, e-posta (matbu veya
          kişiselleştirilmiş) veya diğer şekillerde) gönderiminin sağlanması
          (kimlik bilgisi, iletişim bilgisi, müşteri işlem bilgisi, işlem
          güvenliği bilgisi, pazarlama bilgisi), (Hukuki sebep: Açık rıza) 34.
          Pazarlama analiz çalışmaları, alışveriş alışkanlıkları ve eğilimleri
          analiz edilerek matbu ve/veya segmentasyon yolu ile kişiselleştirilmiş
          pazarlama, her türlü Müşteri İlişkileri Yönetimi (CRM) aktiviteleri,
          satın alınan ürünler dahil Çekmece grup platformu ve/veya araç alım
          hizmeti ve ekspertiz hizmetleri dahil olmak üzere tüm müşteri
          süreçleri ile ilgili geri bildirim alınması ve araştırma yapılması
          amacıyla (müşteri memnuniyet anketleri dahil) her türlü anket ve
          araştırma süreçlerinin ve pazarlama faaliyetlerinin yürütülmesi
          (kimlik bilgisi, iletişim bilgisi, pazarlama bilgisi, müşteri işlem
          bilgisi, işlem güvenliği bilgisi, araç bilgileri), (Hukuki sebep: Açık
          rıza) 35. Müşterilerin Çekmece grup Şirketleri hizmetlerinin
          geliştirilmesi amacıyla, Çekmece merkezlerinin ve sağlanan hizmetlerin
          değerlendirilmesi, puanlanması ve/veya yazılı incelemelerin sosyal
          medya platformlarında (Google vb.) yapılması için elektronik ticari
          ileti (SMS formatında) gönderilmesi (kimlik bilgisi, iletişim bilgisi,
          pazarlama bilgisi, müşteri işlem bilgisi), (Hukuki sebep: Açık rıza)
          36. Fiziksel mekân güvenliğinin ve işyeri sağlığı ve güvenliğinin
          temini kapsamında Çekmece merkezlerinin güvenliğinin sağlanması
          (fiziksel mekân güvenliği bilgisi, görsel ve işitsel kayıt bilgisi),
          (Hukuki sebep: Hukuki yükümlülüklerimizin yerine getirilmesi, bir
          hakkın tesisi, kullanılması ve korunması için veri işlemenin zorunlu
          olması) 37. Müşteri ilişkileri yönetimi süreçlerinin yürütülmesi ve bu
          kapsamda müşterilerin talep/şikâyetlerinin takibinin sağlanması
          (kimlik bilgisi, iletişim bilgisi, pazarlama bilgisi, araç bilgileri,
          işlem güvenliği bilgisi, finans bilgisi, müşteri işlem bilgisi),
          (Hukuki sebep: Sözleşmenin ifası, hukuki yükümlülüklerimizin yerine
          getirilmesi için zorunlu olması, bir hakkın tesisi, kullanılması ve
          korunması için veri işlemenin zorunlu olması) 38. Müşterilerin şikâyet
          süreçlerinin hukuki platformlarda yürütülmesi kapsamında hukuk
          işlerinin takibi ve yürütülmesi (kimlik bilgisi, iletişim bilgisi,
          müşteri işlem bilgisi, hukuki işlem bilgisi, işlem güvenliği bilgisi,
          pazarlama bilgisi), (Hukuki sebep: bir hakkın tesisi, kullanılması ve
          korunması için veri işlemenin zorunlu olması, hukuki
          yükümlülüklerimizin yerine getirilmesi için zorunlu olması, meşru
          menfaat) 39. Yasal yükümlülüklerin ve idari yetkili kurumların
          taleplerinin yerine getirilmesi bağlamında yetkili kişi, kurum ve
          kuruluşlara bilgi verilmesi (kimlik bilgisi, iletişim bilgisi,
          fiziksel mekân güvenliği bilgisi, müşteri işlem bilgisi, finans
          bilgisi, işlem güvenliği bilgisi, pazarlama bilgisi, görsel ve işitsel
          kayıt bilgisi, hukuki işlem bilgisi), (Hukuki sebep: Hukuki
          yükümlülüklerimizin yerine getirilmesi için zorunlu olması, kanunlarda
          açıkça öngörülmesi) 40. Kişisel Verilerin Kimlere ve Hangi Amaçla
          Aktarılabileceği Çekmece grup Şirketleri, Kişisel Verilerinizi, açık
          rızanıza binaen veya hukuken izin verilen diğer hallerde (Bölüm 4’te
          belirtilen amaçlar ve hukuki sebepler çerçevesinde) yurt içinde veya
          yurt dışında; işbirliği yapılan ve/veya hizmet alınan Çekmece grup
          Şirketleri’nin grup şirketlerine, sigorta, güvenlik, yazılım,
          pazarlama (kullanıcı deneyimi (UX) ve pazar araştırma ajansları
          dahil), hukuk, ekspertiz, finans (finansman şirketleri ve bankalar
          dahil), lojistik ve vergi benzeri alanlarda destek alınan danışmanlık
          ve destek firmalarına, işbirliği yapılan finansal kuruluşlara,
          pazarlama amacıyla işbirliği yapılan sosyal medya şirketlerine ve
          Çekmece grup Şirketleri’nin faaliyetleri ile ilgili yukarıda
          belirtilen amaçlar kapsamında sair alanlarda destek veren üçüncü
          kişilere (SMS ve e-posta gönderimi, e-fatura, e-arşiv hizmeti
          sağlanması, CRM (Müşteri İlişkileri Yönetimi), ve bulut bilişim,
          yedekleme desteği veren firmalar ve benzeri), hukuki uyuşmazlıkların
          giderilmesi, mevzuattan kaynaklı yükümlülüklerin yerine getirilmesi
          amacıyla yetkili kurum ve kuruluşlara ve talep halinde adli makamlar
          veya ilgili kolluk kuvvetlerine aktarabilmektedir ve/veya erişme
          açabilmektedir. 41. Kişisel Veri Sahibinin KVKK Madde 11 Kapsamındaki
          Hakları Kişisel veri sahibi olarak dilediğiniz zaman KVKK’nın 11.
          Maddesi uyarınca aşağıda belirtilen haklarınızı veri sorumlusu olan
          Çekmece grup Şirketleri’nden talep edebilirsiniz. Bu kapsamda sahip
          olduğunuz haklar aşağıdaki şekildedir: 42. Kişisel verilerin işlenip
          işlenmediğini öğrenme, 43. Kişisel veriler işlenmişse buna ilişkin
          bilgi talep etme, 44. Kişisel verilerin işlenme amacını ve bunların
          amacına uygun kullanılıp kullanılmadığını öğrenme, 45. Yurt içinde
          veya yurt dışında kişisel verilerin aktarıldığı üçüncü kişileri bilme,
          46. Kişisel verilerin eksik veya yanlış işlenmiş olması hâlinde
          bunların düzeltilmesini isteme ve bu çerçevede yapılan işlemin kişisel
          verilerin aktarıldığı üçüncü kişilere bildirilmesini talep etme, 47.
          KVKK’ya ve ilgili diğer kanun hükümlerine uygun olarak işlenmiş
          olmasına rağmen, işlenmesini gerektiren sebeplerin ortadan kalkması
          hâlinde Kişisel Verilerin Silinmesi, Yok Edilmesi veya Anonim Hale
          Getirilmesi Hakkında Yönetmelik Hükümleri uyarınca bu verilerin 30
          (otuz) gün içerisinde silinmesini, yok edilmesini veya anonim hale
          getirilmesini ve bu kapsamda yapılan işlemin kişisel verilerin
          aktarıldığı üçüncü kişilere bildirilmesini isteme, 48. İşlenen
          verilerin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi
          suretiyle kişinin kendisi aleyhine bir sonucun ortaya çıkmasına itiraz
          etme, 49. Kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle
          zarara uğranması hâlinde zararın giderilmesini talep etme. Yukarıda
          belirtilen talepler yazılı olarak veya Kişisel Verileri Koruma Kurulu
          (‘’Kurul’’) tarafından belirlenecek diğer yöntemlerle veri sorumlusu
          olarak Çekmece grup Şirketleri’ne iletilecektir. Bu kapsamda,
          yukarıdaki haklarınıza ilişkin talebinizi aşağıda verilen ve zaman
          zaman değişebilecek olan e-posta adresimize, kayıtlı elektronik posta
          (KEP) adresiniz vasıtasıyla, güvenli elektronik imzalı, mobil imzalı
          ya da tarafınızca Çekmece grup Şirketleri’ne daha önce bildirilen ve
          Çekmece grup Şirketleri’nin sisteminde kayıtlı bulunan elektronik
          posta adresinizi kullanmak suretiyle (kimliğinizi tespit edici
          belgeleri ekleyerek) veya yine aşağıda yer alan ve zaman zaman
          değişebilecek olan posta adresimize kimliğinizi tespit edici belgeler
          ile ıslak imzalı bir dilekçe ile elden teslim edebilir ya da noter
          aracılığıyla gönderebilirsiniz. Kişisel veri sahibi olarak sahip
          olduğunuz ve yukarıda belirtilen haklarınızı kullanmak için
          yapacağınız ve kullanmayı talep ettiğiniz hakka ilişkin açıklamaları
          içeren başvuruda; talep ettiğiniz hususun açık ve anlaşılır olması,
          talep ettiğiniz konunun şahsınız ile ilgili olması veya başkası adına
          hareket ediyorsanız bu konuda özel olarak yetkili olmanız ve bu
          yetkinin belgelendirilmesi, başvurunun kimlik ve adres bilgilerinizi
          içermesi gerekmektedir. Çekmece grup Şirketleri başvuruda yer alan
          talepleri, talebin niteliğine göre en kısa sürede ve en geç 30 (otuz)
          gün içinde (bir maliyeti olmaması halinde) ücretsiz olarak
          sonuçlandırır. Ancak, işlemin ayrıca bir maliyeti gerektirmesi
          hâlinde, Kurul tarafından belirlenecek olan tarifedeki ücret
          alınabilir. Başvurulara verilecek cevapların 10 (on) sayfayı aşması
          halinde, her sayfa için 1,00 (bir) TL işlem ücreti alınacaktır.
          Cevabın CD, flash bellek gibi kayıt ortamında verilmesinin istenmesi
          halinde, istenen kayıt ortamının maliyetine göre ücret talep
          edilecektir. Veri Sorumlusu: Çekmece grup Services B.V. ve Çekmece
          grup Mobil İnternet Servisleri ve Ticaret A.Ş. Posta adresi: Ayazağa
          Mahallesi, Azerbaycan Caddesi, 2B Blok, No:3K İç Kapı No:13 Sarıyer,
          İstanbul E- posta adresi: privacy@çekmece grup.com KEP adresi: çekmece
          grup@hs03.kep.tr
        </p>
      </div>
    </div>
  );
};

export default Privacy;
