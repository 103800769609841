import { useEffect, useState } from "react";

const Landing = () => {
  const [os, setOs] = useState("");

  useEffect(() => {
    if (
      navigator.platform.indexOf("Mac") === 0 ||
      navigator.platform === "iPhone"
    ) {
      console.log("On Mac");
      setOs("Mac");
      window.location.replace("https://apps.apple.com/us/app/çekmece/id6444366862");
    } else {
      console.log("On windows");
	  setOs("Other");
      window.location.replace(
        "https://play.google.com/store/apps/details?id=com.cekmece"
      );
    }
  }, []);
  return (
    <div>
      <h1></h1>
    </div>
  );
};

export default Landing;
