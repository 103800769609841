import React, { useState, useEffect } from "react";
//Components
import PageTitle from "../components/Panel/PageTitle";
import TableContainerBanners from "../components/TableContainerBanners";
//Modals
import BannerModal from "../components/Modals/BannerModal";
//API
import useAxiosPrivate from "../hooks/useAxiosPrivate";

const title = "Banner";

const API_URL = "/get_banners";

const BannersInside = () => {
  const axiosPrivate = useAxiosPrivate();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const [open, setOpen] = useState(false);

  const closeModal = () => {
    setOpen(false);
  };

  const fetchDatas = async () => {
    setLoading(true);
    try {
      let parameters = {};
      const response = await axiosPrivate.post(
        API_URL,
        JSON.stringify(parameters),
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );
      setLoading(false);
      if (response.status === 200) {
        setData(response.data.data);
      }
    } catch (err) {
      alert(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDatas();
  }, []);

  return (
    <>
      <PageTitle title={title} banner={true} setVisible={setOpen} />
      <TableContainerBanners
        data={data}
        loading={loading}
        onDone={() => {
          setOpen(false);
          fetchDatas();
        }}
      />
      <BannerModal
        open={open}
        onClose={closeModal}
        onDone={() => {
          setOpen(false);
          fetchDatas();
        }}
      />
    </>
  );
};

export default BannersInside;
