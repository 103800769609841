import React, { useState } from "react";
import Table from "react-bootstrap/Table";
//Components
import Pagination from "./Pagination";

const TableContainerUsers = ({ data, loading }) => {
  const [currentPage, setCurrentPage] = useState(1);

  const data_per_page = 10;
  const indexOfLastData = currentPage * data_per_page;
  const indexOfFirstData = indexOfLastData - data_per_page;
  const currentDatas = data.slice(indexOfFirstData, indexOfLastData);

  return (
    <>
      <div className="table_container">
        {loading === true ? (
          <>Yükleniyor</>
        ) : (
          <>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>İsim</th>
                  <th>Soyisim</th>
                  <th>Telefon</th>
                  <th>Kayıt Tarihi</th>
                </tr>
              </thead>
              <tbody>
                {currentDatas.map((user) => (
                  <tr>
                    <td>{user._id}</td>
                    <td>{user.first_name}</td>
                    <td>{user.last_name}</td>
                    <td>+90 {user.phone}</td>
                    <td>{user?.created_at?.split("T")[0]}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <Pagination
              dataPerPage={data_per_page}
              totalData={data.length}
              paginate={(index) => setCurrentPage(index)}
            />
          </>
        )}
      </div>
    </>
  );
};

export default TableContainerUsers;
