//Components
import PanelContainer from "../components/Layouts/PanelContainer";
import FeedbacksInside from "./FeedbacksInside";
import Unauthorized from "../components/Olds/Unauthorized";
//Hooks
import useAuth from "../hooks/useAuth";
//Constants
import Roles from "../constants/Roles";

const Feedbacks = () => {
  const { auth } = useAuth();

  return auth.roles.includes(Roles.Admin) ? (
    <PanelContainer>
      <FeedbacksInside />
    </PanelContainer>
  ) : (
    <Unauthorized />
  );
};

export default Feedbacks;
