//Components
import PanelContainer from "../components/Layouts/PanelContainer";
import Users from "../pages/Users";
import Unauthorized from "../components/Olds/Unauthorized";
//Hooks
import useAuth from "../hooks/useAuth";
//Constants
import Roles from "../constants/Roles";

const Panel = () => {
  const { auth } = useAuth();

  return auth.roles.includes(Roles.Admin) ? (
    <PanelContainer>
      <Users />
    </PanelContainer>
  ) : (
    <Unauthorized />
  );
};

export default Panel;
