import { useEffect, useState } from "react";
//Components
import SideBar from "./Sidebar";
import Navbar from "./Navbar";
//Hooks
import useWindowSize from "../../hooks/useWindowSize";

const PanelContainer = ({ children }) => {
  const [width] = useWindowSize();

  const [open, setOpen] = useState(true);

  useEffect(() => {
    if (open) {
      document.querySelector("#panel").classList.add("open");
    } else {
      document.querySelector("#panel").classList.remove("open");
    }
  }, [open]);

  useEffect(() => {
    if (width < 1000) {
      deleteAllClasses();
      document.querySelector("#panel").classList.add("small");
    } else if (width < 1400) {
      deleteAllClasses();
      document.querySelector("#panel").classList.add("medium");
    } else {
      deleteAllClasses();
      document.querySelector("#panel").classList.add("large");
    }
  }, [width]);

  const deleteAllClasses = () => {
    document.querySelector("#panel").classList.remove("large");
    document.querySelector("#panel").classList.remove("medium");
    document.querySelector("#panel").classList.remove("small");
  };

  return (
    <div className="panel_container">
      <SideBar open={open} setOpen={setOpen} />
      <div className="panel_content_container large" id="panel">
        <Navbar />
        <div className="panel_inside">{children}</div>
      </div>
    </div>
  );
};

export default PanelContainer;
