import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyD5gibg-t9omhtvLI5QC4hTHZFmq80k1ZY",
  authDomain: "cekmece-bfee7.firebaseapp.com",
  projectId: "cekmece-bfee7",
  storageBucket: "cekmece-bfee7.appspot.com",
  messagingSenderId: "313197606046",
  appId: "1:313197606046:web:61ef07805f31f04c9bf78f",
  measurementId: "G-TF6GNQRFSD",
};

const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
